import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { RESOURCE_TYPE_TO_NAMES } from "../../constants/resource-names.constants";
import ButtonFormElement, { FormButtonType } from "../../elements/button-form.elements";
import FormElement from "../../elements/form.elements";
import InputElement, { InputTypes } from "../../elements/input.elements";
import { ResourceType } from "../../models/resource.models";

interface IAddResourcesWidgetProps {
  loading: boolean
  name: string
  setName: Dispatch<SetStateAction<string>>
  title: string
  setTitle: Dispatch<SetStateAction<string>>
  description: string
  setDescription: Dispatch<SetStateAction<string>>
  resourceType: ResourceType
  onClickAdd: () => void
}

const AddResourcesWidget: FunctionComponent<IAddResourcesWidgetProps> = ({
  loading,
  name,
  setName,
  title,
  setTitle,
  description,
  setDescription,
  resourceType,
  children,
  onClickAdd
}) => {
  return <div className="container max-w-screen-lg px-2 py-6">
    <FormElement onSubmit={onClickAdd}>
      <InputElement title="Name" type={InputTypes.TEXT} value={name} onChange={setName} />
      <InputElement title="Title" type={InputTypes.TEXT} value={title} onChange={setTitle} />
      <InputElement title="Description" type={InputTypes.TEXT} value={description} onChange={setDescription} />
      
      {children}

      <ButtonFormElement type={FormButtonType.SUBMIT} title={`ADD ${RESOURCE_TYPE_TO_NAMES[resourceType]}`} loading={loading} />
    </FormElement>
  </div>
}

export default AddResourcesWidget