import { FunctionComponent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RESOURCE_STRING_TO_TYPE } from "../../constants/resource-names.constants";
import InputElement, { InputTypes } from "../../elements/input.elements";
import ResourcesPostNet from "../../http/resources/post.resources.http";
import { ResourceTextLanguages, ResourceType } from "../../models/resource.models";
import AddResourcesWidget from "./add.resources.widgets";

interface IAddTextResourcesWidgetProps {
  resourceType: string
  uuidParentResource?: string
}

const AddTextResourcesWidget: FunctionComponent<IAddTextResourcesWidgetProps> = ({
  uuidParentResource,
  resourceType
}) => {
  const navigate = useNavigate()

  const [ loading, setLoading ] = useState(false)
  const [ name, setName ] = useState("")
  const [ title, setTitle ] = useState("")
  const [ description, setDescription ] = useState("")
  const [ lang, setLang ] = useState<{[key in keyof typeof ResourceTextLanguages]?: string}>({ })

  async function onClickAdd() {
    setLoading(true)
    const response = await new ResourcesPostNet().createText({
      name,
      title,
      description,
      lang,
      date: new Date(),
      managerCanManage: true
    })
    setLoading(false)
    navigate(-1)
  }

  return resourceType? <AddResourcesWidget
    key={resourceType}
    loading={loading}
    name={name}
    setName={setName}
    title={title}
    setTitle={setTitle}
    description={description}
    setDescription={setDescription}
    resourceType={RESOURCE_STRING_TO_TYPE[resourceType]}
    onClickAdd={onClickAdd}
  >
    <InputElement title="Text (English)" type={InputTypes.TEXT} onChange={value => setLang({ ENGLISH: value })} />
  </AddResourcesWidget>: <></>
}

export default AddTextResourcesWidget