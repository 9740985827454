import { FunctionComponent, ReactNode } from "react"

interface IContainerDashboardComponentProps {
  header: ReactNode
}

const ContainerDashboardComponent: FunctionComponent<IContainerDashboardComponentProps> = ({ header, children }) => {
  return <div>
    {header}
    <div>
      {children}
    </div>  
  </div>
}

export default ContainerDashboardComponent