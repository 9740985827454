import { FunctionComponent } from "react"
import LoadingElement from "./loading.elements"

export enum FormButtonType {
  SUBMIT = "submit",
  RESET = "reset"
}

interface IButtonFormElementProps {
  type: FormButtonType
  title: string
  loading?: boolean
  disabled?: boolean
  backgroundColor?: string
}

const ButtonFormElement: FunctionComponent<IButtonFormElementProps> = ({
  type,
  title,
  loading = false,
  disabled = false,
}) => {
  return <button type={type} disabled={loading || disabled} className={`flex flex-1 flex-col border border-sky-200 bg-sky-200 my-4 rounded-lg`}>
    <div className="flex flex-1 self-center px-4 py-2">
      {loading? <LoadingElement />: <p className="flex flex-1 self-center font-semibold uppercase">{title}</p>}
    </div>
  </button>
}

export default ButtonFormElement