import { ResourceType } from "../models/resource.models";

const RESOURCE_TYPE_TO_NAMES: Record<ResourceType, string> = {
  TEXT: "Text",
  IMAGE: "Image",
  ALBUM: "Album",
  GALLERY: "Gallery",
  VIDEO: "Video",
  AUDIO: "Audio",
  PDF: "Pdf"
}

const RESOURCE_TYPE_TO_STRING: Record<ResourceType, string> = {
  TEXT: "text",
  IMAGE: "image",
  ALBUM: "album",
  GALLERY: "gallery",
  VIDEO: "video",
  AUDIO: "audio",
  PDF: "pdf"
}

const RESOURCE_STRING_TO_TYPE: Record<string, ResourceType> = {
  text: ResourceType.TEXT,
  image: ResourceType.IMAGE,
  album: ResourceType.ALBUM,
  gallery: ResourceType.GALLERY,
  video: ResourceType.VIDEO,
  audio: ResourceType.AUDIO,
  pdf: ResourceType.PDF
}

const RESOURCE_CHILDREN_TYPES: Record<ResourceType, Array<ResourceType>> = {
  TEXT: [],
  IMAGE: [],
  ALBUM: [
    ResourceType.IMAGE
  ],
  GALLERY: [
    ResourceType.ALBUM
  ],
  VIDEO: [],
  AUDIO: [],
  PDF: []
}

export {
  RESOURCE_TYPE_TO_NAMES,
  RESOURCE_STRING_TO_TYPE,
  RESOURCE_TYPE_TO_STRING,
  RESOURCE_CHILDREN_TYPES
}