import { FunctionComponent } from "react"
import { Outlet } from "react-router-dom"
import PrimarySidebarWidget from "../../widgets/sidebar/primary.sidebar.widgets"
import SecondarySidebarWidget from "../../widgets/sidebar/secondary.sidebar.widgets"

const DashboardPage: FunctionComponent = () => {
  return <div className="flex flex-1 flex-row">
    <div className="flex w-[360px] h-full">
      <div className="flex w-[60px] h-full">
        <PrimarySidebarWidget />
      </div>
      <div className="flex flex-1 h-full">
        <SecondarySidebarWidget />
      </div>
    </div>
    <div className="flex flex-1 flex-col overflow-auto">
      <Outlet />
    </div>
  </div>
}

export default DashboardPage