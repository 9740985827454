import { FunctionComponent } from "react"
import { useParams } from "react-router-dom"
import { RESOURCE_STRING_TO_TYPE } from "../../../constants/resource-names.constants"
import ShowResourcesWidget from "../../../widgets/resources/show.resources.widgets"

const ShowResourcesDashboardPages: FunctionComponent = () => {
  const { resourceType, uuidResource } = useParams()

  return resourceType && uuidResource? (
    <ShowResourcesWidget
      key={resourceType}
      resourceType={RESOURCE_STRING_TO_TYPE[resourceType]}
      uuidResource={uuidResource}
    />
  ): <></>
}

export default ShowResourcesDashboardPages