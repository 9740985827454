import { FunctionComponent } from "react";

export enum InputTypes {
  TEXT = "text",
  PASSWORD = "password"
}

type IInputTextProps = {
  title: string
  placeholder?: string
  type: InputTypes.TEXT
  value?: string
  onChange: (value: string) => void
}

type IInputPasswordProps = {
  title: string
  placeholder?: string
  type: InputTypes.PASSWORD
  value?: string
  onChange: (value: string) => void
}

type IInputElementProps = IInputTextProps | IInputPasswordProps

const InputElement: FunctionComponent<IInputElementProps> = ({ type, value, onChange, title, placeholder }) => {
  return <>
    <label
      htmlFor={title}
      className="mt-4"
    >{title}</label>
    <input
      type={type}
      name={title}
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder || title}
      className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-2 mt-1"
    />
  </>
}

export default InputElement