import { ResourceModel, ResourceType } from "../../models/resource.models"
import Net, { IQuery } from "../net.http"

export interface IResourcesGetHttpGetData {
  type: ResourceType
}

export interface IResourcesGetHttpGetSingleData {
  uuid: string
  getChildren: boolean
}

export interface IResourcesGetHttpGetResponse {
  resources: Array<ResourceModel>
}

export interface IResourcesGetHttpGetSingleResponse {
  resource: ResourceModel
  children: Array<ResourceModel>
}

export default class ResourcesGetHttp {
  async get({ type }: IResourcesGetHttpGetData): Promise<IResourcesGetHttpGetResponse> {
    try {
      const query: IQuery = { type }
      const response = await Net.getInstance().get<IResourcesGetHttpGetResponse>({
        path: `/resources`,
        query
      })
      return response.success
    } catch (error) {
      throw error
    }
  }

  async getSingle({ uuid, getChildren }: IResourcesGetHttpGetSingleData): Promise<IResourcesGetHttpGetSingleResponse> {
    try {
      const query: IQuery = {
        getChildren: getChildren
      }
      const response = await Net.getInstance().get<IResourcesGetHttpGetSingleResponse>({
        path: `/resources/${uuid}`,
        query
      })
      return response.success 
    } catch (error) {
      throw error
    }
  }
}