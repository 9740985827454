import { FunctionComponent } from "react";

export enum InputFileTypes {
  JPEG = ".jpeg",
  JPG = ".jpg",
  PNG = ".png",
  GIF = ".gif"
}

type IInputFileElementProps = {
  title: string
  placeholder?: string
  types: Array<InputFileTypes>
  value?: string
  onChange: (value: File|null) => void
}

const InputFileElement: FunctionComponent<IInputFileElementProps> = ({
  types,
  value,
  onChange,
  title,
  placeholder
}) => {
  console.log("types accepteed", types)
  return <>
    <label
      htmlFor={title}
      className="mt-4"
    >{title}</label>
    <input
      className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700
        focus:bg-white
        focus:border-blue-600
        focus:outline-none
      "
      type="file"
      value={value}
      accept={types.join(",")}
      onChange={e => onChange(e.target.files? e.target.files[0]: null)}
    />
  </>
}

export default InputFileElement