import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RESOURCE_STRING_TO_TYPE } from "../../constants/resource-names.constants";
import ResourcesPostNet from "../../http/resources/post.resources.http";
import AddResourcesWidget from "./add.resources.widgets";

interface IAddGalleryResourcesWidgetProps {
  resourceType: string
  uuidParentResource?: string
}

const AddGalleryResourcesWidget: FunctionComponent<IAddGalleryResourcesWidgetProps> = ({ uuidParentResource, resourceType }) => {
  const navigate = useNavigate()

  const [ loading, setLoading ] = useState(false)
  const [ name, setName ] = useState("")
  const [ title, setTitle ] = useState("")
  const [ description, setDescription ] = useState("")

  async function onClickAdd() {
    setLoading(true)
    const response = await new ResourcesPostNet().createGallery({
      name,
      title,
      description,
      date: new Date(),
      managerCanManage: true
    })
    setLoading(false)
    navigate(-1)
  }

  return resourceType? <AddResourcesWidget
    key={resourceType}
    loading={loading}
    name={name}
    setName={setName}
    title={title}
    setTitle={setTitle}
    description={description}
    setDescription={setDescription}
    resourceType={RESOURCE_STRING_TO_TYPE[resourceType]}
    onClickAdd={onClickAdd}
  />: <></>
}

export default AddGalleryResourcesWidget