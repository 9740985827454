import { FunctionComponent } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAppSelector } from "../redux/hooks"
import { selectUserIsLoggedIn } from "../redux/slices/user.slices.redux"

const RequireAuthenticationElement: FunctionComponent = () => {
  const isLoggedIn = useAppSelector(selectUserIsLoggedIn)
  const location = useLocation()

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} />
  }

  return <Outlet />
}

export default RequireAuthenticationElement