import { FunctionComponent, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import LoadingElement from "../../elements/loading.elements"
import LoginAuthPostHttp from "../../http/auth/login/post.login.auth.http"
import Net from "../../http/net.http"
import { sliceUserLogin } from "../../redux/slices/user.slices.redux"

const LoginPage: FunctionComponent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [ username, setUsername ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ loading, setLoading ] = useState(false)

  async function onClickLogin(username: string, password: string) {
    if (!username.length || !password.length) return
    setLoading(true)
    try {
      const response = await new LoginAuthPostHttp().login({ username, password })
      Net.getInstance().login(response.access)
      dispatch(sliceUserLogin({ isLoggedIn: true }))
      navigate("/")
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return <div className="flex flex-1 bg-sky-100">
    <div className="container max-w-[450px] flex flex-1 flex-col justify-center px-2">
      <h1 className="text-3xl text-center font-bold mb-2 text-black/20">JILLION</h1>
        <div className="p-4 rounded-xl bg-white">
        <div className="flex flex-1 flex-row justify-between items-end">
          <h2 className="text-4xl font-bold mb-2 text-neutral-400">Login </h2>
        </div>
        <form onSubmit={ev => ev.preventDefault()} className="flex flex-1 flex-col">
          <label
            htmlFor="username"
            className="mt-4"
          >Username</label>
          <input
            placeholder="Username"
            type="text"
            name="username"
            onChange={ev => setUsername(ev.target.value)}
            className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-2 mt-1"
          />
          <label
            htmlFor="password"
            className="mt-4"
          >Password</label>
          <input
            placeholder="Password"
            type="password"
            name="password"
            onChange={ev => setPassword(ev.target.value)}
            className="flex flex-1 border border-neutral-200 rounded-lg px-4 py-2 mt-1"
          />
          <button
            onClick={() => onClickLogin(username, password)}
            className="border border-neutral-200 rounded-lg px-4 py-2 mt-6 bg-sky-200 border-sky-200 font-bold text-center cursor-pointer"
          >
            {loading? <LoadingElement />: <p>LOGIN</p>}
          </button>
        </form>
      </div>
    </div>
  </div>
}

export default LoginPage