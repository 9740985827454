import { FunctionComponent } from "react"
import { NavLink } from "react-router-dom"

interface ISecondarySidebarListItems {
  title: string
  path: string
}

const resourceTypeData: Array<ISecondarySidebarListItems> = [{
  title: "Text",
  path: "text"
}, {
  title: "Image",
  path: "image"
}, {
  title: "Album",
  path: "album"
}, {
  title: "Gallery",
  path: "gallery"
}]

const SecondarySidebarListComponent: FunctionComponent<{ sidebarItems: Array<ISecondarySidebarListItems> }> = ({ sidebarItems }) => {
  return <ul>
    {sidebarItems.map(item => <SecondarySidebarListItemComponent key={item.path} sidebarItem={item} />)}
  </ul>
}

const SecondarySidebarListItemComponent: FunctionComponent<{ sidebarItem: ISecondarySidebarListItems }> = ({ sidebarItem }) => {
  return <li>
    <NavLink to={`/resources/${sidebarItem.path}`} className={({ isActive }) => `flex flex-1 relative m-1 p-2 br-4 rounded-lg font-medium ${isActive? "bg-sky-200 hover:bg-sky-200": "transparent hover:bg-neutral-300"}`}>
      <p>{sidebarItem.title}</p>
    </NavLink>
  </li>
}

const ResourcesSecondarySidebarWidget: FunctionComponent = () => {
  return <>
    <p className="text-2xl px-3 pt-4 pb-2 font-medium">Resources</p>
    <SecondarySidebarListComponent sidebarItems={resourceTypeData} />
  </>
}

export default ResourcesSecondarySidebarWidget