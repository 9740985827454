import { FunctionComponent, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ContainerDashboardComponent from "../../../components/container.components"
import { RESOURCE_CHILDREN_TYPES, RESOURCE_TYPE_TO_NAMES, RESOURCE_STRING_TO_TYPE } from "../../../constants/resource-names.constants"
import LoadingElement from "../../../elements/loading.elements"
import ResourcesGetHttp from "../../../http/resources/get.resources.http"
import { ResourceModel, ResourceType } from "../../../models/resource.models"
import DefaultHeaderWidget from "../../../widgets/default-header.widgets"
import AddAlbumResourcesWidget from "../../../widgets/resources/add.album.resources.widgets"
import AddGalleryResourcesWidget from "../../../widgets/resources/add.gallery.resources.widgets"
import AddImagesResourcesWidget from "../../../widgets/resources/add.image.resources.widget"
import AddTextResourcesWidget from "../../../widgets/resources/add.text.resources.widgets"

const RESOURCE_VIEW_MAPPING: Record<ResourceType, FunctionComponent<{
  resourceType: string
  uuidParentResource?: string
}>> = {
  GALLERY: AddGalleryResourcesWidget,
  ALBUM: AddAlbumResourcesWidget,
  IMAGE: AddImagesResourcesWidget,
  VIDEO: AddTextResourcesWidget,
  AUDIO: AddTextResourcesWidget,
  PDF: AddTextResourcesWidget,
  TEXT: AddTextResourcesWidget
}

const AddChildrenResourcesDashboardPage: FunctionComponent = () => {
  const { resourceType, uuidResource, childResourceType } = useParams()

  const [ loading, setLoading ] = useState<boolean>(true)
  const [ parentResource, setParentResource ] = useState<ResourceModel|undefined>()

  useEffect(() => {
    let didCancel = false

    async function fetchData(uuid: string) {
      setLoading(true)
      const response = await new ResourcesGetHttp().getSingle({
        uuid,
        getChildren: false
      })
      console.log(response)
      if (!didCancel) {
        setParentResource(response.resource)
        setLoading(false)
      }
    }

    if (uuidResource) {
      fetchData(uuidResource)
    } else {
      setLoading(false)
    }
    return () => { didCancel = true }
  }, [ uuidResource ])

  if (childResourceType === undefined || resourceType === undefined) return <></>

  let view
  if (loading) {
    view = <LoadingElement />
  } else if (parentResource) {
    const resource = RESOURCE_STRING_TO_TYPE[resourceType]
    if (RESOURCE_CHILDREN_TYPES[resource].length) {
      const View = RESOURCE_VIEW_MAPPING[RESOURCE_STRING_TO_TYPE[childResourceType]]
      view = <View resourceType={childResourceType} uuidParentResource={uuidResource} />
    } else {
      view = <div className="p-4 rounded-lg col-span-3">
        <p className="text-center">Adding children to {RESOURCE_TYPE_TO_NAMES[resource]} is not possible</p>
      </div>
    }
  } else {
    return <></>
  }

  const header = <DefaultHeaderWidget
    title={`Add ${RESOURCE_TYPE_TO_NAMES[RESOURCE_STRING_TO_TYPE[childResourceType]]} Resource`}
  />

  return <ContainerDashboardComponent header={header}>
    <p>Parent - {parentResource?.name}</p>
    {view}
  </ContainerDashboardComponent>
}

export default AddChildrenResourcesDashboardPage