import { useEffect } from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import LoadingElement from "./elements/loading.elements"
import RequireAuthenticationElement from "./elements/require-authentication.elements"
import AuthPage from "./pages/auth/index.auth.pages"
import LoginPage from "./pages/auth/login.auth.pages"
import DashboardPage from "./pages/dashboard/index.dashboard.pages"
import IndexResourcesDashboardPages from "./pages/dashboard/resources/index.resources.dashboard.pages"
import { useAppDispatch, useAppSelector } from "./redux/hooks"
import { selectUserInitialized, sliceUserInitialize } from "./redux/slices/user.slices.redux"
import AddResourcesDashboardPage from "./pages/dashboard/resources/add.resources.dashboard.pages"
import ShowResourcesDashboardPages from "./pages/dashboard/resources/show.resources.dashboard.pages"
import AddChildrenResourcesDashboardPage from "./pages/dashboard/resources/add-children.resources.dashboard.pages"

function App() {
  const isUserInitialized = useAppSelector(selectUserInitialized)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(sliceUserInitialize({ isLoggedIn: false }))
  }, [ ])

  const view = isUserInitialized
    ? <Routes>
        <Route index element={<Navigate replace to={"/resources"} />} />
        <Route path="auth" element={<AuthPage />}>
          <Route path="login" element={<LoginPage />} />
          {/* <Route path="logout" element={<LoginPage />} /> */}
        </Route>
        <Route element={<RequireAuthenticationElement />}>
          <Route path="resources" element={<DashboardPage />}>
            <Route path=":resourceType" element={<IndexResourcesDashboardPages />} />
            <Route path=":resourceType/new" element={<AddResourcesDashboardPage />} />
            <Route path=":resourceType/:uuidResource" element={<ShowResourcesDashboardPages />} />
            <Route path=":resourceType/:uuidResource/:childResourceType/new" element={<AddChildrenResourcesDashboardPage />} />
          </Route>
          <Route path="profile" element={<p>Profile</p>} />
        </Route>
      </Routes>
    : <div className="flex flex-1 justify-center flex-col">
        <LoadingElement />
      <p className="text-center pt-4 text-neutral-400">Logging In...</p>
    </div>

  return view
}

export default App
