import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { WritableDraft } from "immer/dist/internal"
import { RootState } from "../store.redux"

interface IUserState {
  initialized: boolean
  isLoggedIn: boolean
}

interface ISliceUserInitializeAction {
  isLoggedIn: boolean
}

interface ISliceUserLoginAction extends ISliceUserInitializeAction { }

export const USER_SLICE_NAME = "USER_SLICE"

const initialState: IUserState = {
  initialized: false,
  isLoggedIn: false
}

const sliceUserInitializeReducer = (state: WritableDraft<IUserState>, action: PayloadAction<ISliceUserLoginAction>) => {
  state.initialized = true
  state.isLoggedIn = action.payload.isLoggedIn
}

const sliceUserLoginReducer = (state: WritableDraft<IUserState>, action: PayloadAction<ISliceUserInitializeAction>) => {
  state.isLoggedIn = action.payload.isLoggedIn
}

export const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {
    sliceUserInitialize: sliceUserInitializeReducer,
    sliceUserLogin: sliceUserLoginReducer
  }
})

export const {
  sliceUserInitialize,
  sliceUserLogin
} = userSlice.actions

export const selectUserInitialized = (state: RootState) => state.USER_SLICE.initialized
export const selectUserIsLoggedIn = (state: RootState) => state.USER_SLICE.isLoggedIn

const UserSlice = userSlice.reducer

export default UserSlice