import { FormEvent, FunctionComponent } from "react";

interface IFormElementProps {
  onSubmit: () => void
}

const FormElement: FunctionComponent<IFormElementProps> = ({ children, onSubmit }) => {
  
  function onClickSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    onSubmit()
  }

  return <form
    onSubmit={onClickSubmit}
    className="flex flex-1 flex-col"
  >
    {children}
  </form>
}

export default FormElement