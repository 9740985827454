import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RESOURCE_STRING_TO_TYPE } from "../../constants/resource-names.constants";
import InputFileElement, { InputFileTypes } from "../../elements/input-file.elements";
import ResourcesPostNet from "../../http/resources/post.resources.http";
import AddResourcesWidget from "./add.resources.widgets";

interface IAddImageResourcesWidgetProps {
  resourceType: string
  uuidParentResource?: string
}

const AddImagesResourcesWidget: FunctionComponent<IAddImageResourcesWidgetProps> = ({
  resourceType,
  uuidParentResource
}) => {
  const navigate = useNavigate()

  const [ loading, setLoading ] = useState(false)
  const [ name, setName ] = useState("")
  const [ title, setTitle ] = useState("")
  const [ description, setDescription ] = useState("")
  const [ file, setFile ] = useState<File|null>(null)

  async function onClickAdd() {
    setLoading(true)
    if (file) {
      const response = await new ResourcesPostNet().createImage({
        name,
        title,
        description,
        file,
        date: new Date(),
        managerCanManage: true,
        uuidParentResource,
      })
    }
    setLoading(false)
    navigate(-1)
  }

  return resourceType? <AddResourcesWidget
    key={resourceType}
    loading={loading}
    name={name}
    setName={setName}
    title={title}
    setTitle={setTitle}
    description={description}
    setDescription={setDescription}
    resourceType={RESOURCE_STRING_TO_TYPE[resourceType]}
    onClickAdd={onClickAdd}
  >
    <InputFileElement
      title="Image"
      types={[
        InputFileTypes.PNG,
        InputFileTypes.JPG,
        InputFileTypes.JPEG
      ]}
      onChange={value => setFile(value)}
    />
  </AddResourcesWidget>: <></>
}

export default AddImagesResourcesWidget