import { FunctionComponent, ReactNode } from "react"
import { NavLink } from "react-router-dom"

import { ReactComponent as ResourcesSvg } from "../../constants/svg/resources.svg"
import { ReactComponent as ProfileSvg } from "../../constants/svg/profile.svg"

interface ISidebarDashboardListProps {
  routes: Array<IRoute>
}

interface ISidebarDashboardListItemProps {
  route: IRoute
}

interface IRoute {
  path: string
  title: string
  icon: ReactNode
}

const routesData: Array<IRoute> = [{
  path: "/resources",
  title: "Resources",
  icon: <ResourcesSvg />
// }, {
//   path: "profile",
//   title: "Profile",
//   icon: <ProfileSvg />
}]

const SidebarDashboardList: FunctionComponent<ISidebarDashboardListProps> = ({ routes }) => {
  return <ul>
    {routes.map(route => <SidebarDashboardListItem key={route.path} route={route} />)}
  </ul>
}

const SidebarDashboardListItem: FunctionComponent<ISidebarDashboardListItemProps> = ({ route }) => {
  return <li>
    <NavLink to={route.path} className={({ isActive }) => `flex flex-1 relative m-1 p-2 br-4 rounded-lg font-medium ${isActive? "bg-sky-200 hover:bg-sky-200": "transparent hover:bg-neutral-300"}`}>
      {route.icon}
    </NavLink>
  </li>
}

const PrimarySidebarWidget: FunctionComponent = () => {
  return <div className="flex flex-1 flex-col border-r border-r-neutral-200">
    <SidebarDashboardList routes={routesData} />
  </div>
}

export default PrimarySidebarWidget