import { FunctionComponent } from "react"
import { useParams } from "react-router-dom"
import ContainerDashboardComponent from "../../../components/container.components"
import { RESOURCE_TYPE_TO_NAMES, RESOURCE_STRING_TO_TYPE } from "../../../constants/resource-names.constants"
import { ResourceType } from "../../../models/resource.models"
import DefaultHeaderWidget from "../../../widgets/default-header.widgets"
import AddAlbumResourcesWidget from "../../../widgets/resources/add.album.resources.widgets"
import AddGalleryResourcesWidget from "../../../widgets/resources/add.gallery.resources.widgets"
import AddImagesResourcesWidget from "../../../widgets/resources/add.image.resources.widget"
import AddTextResourcesWidget from "../../../widgets/resources/add.text.resources.widgets"

const RESOURCE_VIEW_MAPPING: Record<ResourceType, FunctionComponent<{
  resourceType: string
  uuidParentResource?: string
}>> = {
  GALLERY: AddGalleryResourcesWidget,
  ALBUM: AddAlbumResourcesWidget,
  IMAGE: AddImagesResourcesWidget,
  VIDEO: AddTextResourcesWidget,
  AUDIO: AddTextResourcesWidget,
  PDF: AddTextResourcesWidget,
  TEXT: AddTextResourcesWidget
}

const AddResourcesDashboardPage: FunctionComponent = () => {
  const { resourceType } = useParams()

  if (resourceType === undefined) return <></>

  const resource = RESOURCE_STRING_TO_TYPE[resourceType]
  const View = RESOURCE_VIEW_MAPPING[resource]

  const header = <DefaultHeaderWidget
    title={`Add ${RESOURCE_TYPE_TO_NAMES[RESOURCE_STRING_TO_TYPE[resourceType]]} Resource`}
  />

  return <ContainerDashboardComponent header={header}>
    <View resourceType={resourceType} />
  </ContainerDashboardComponent>
}

export default AddResourcesDashboardPage