import { ResourceModel, ResourceTextLanguages, ResourceType } from "../../models/resource.models";
import Net from "../net.http";

interface IResourcesPostNetCreateRequestBase {
  name: string
  title: string
  description: string
  date: Date
  managerCanManage: boolean
}

export interface IResourcesPostNetCreateGalleryRequest extends IResourcesPostNetCreateRequestBase { }

export interface IResourcesPostNetCreateAlbumRequest extends IResourcesPostNetCreateRequestBase {
  uuidParentResource?: string
}

export interface IResourcesPostNetCreateTextRequest extends IResourcesPostNetCreateRequestBase {
  lang: {[key in keyof typeof ResourceTextLanguages]?: any}
}

export interface IResourcesPostNetCreateImageRequest extends IResourcesPostNetCreateRequestBase {
  uuidParentResource?: string
  file: File
}

export default class ResourcesPostNet {
  async createText(data: IResourcesPostNetCreateTextRequest): Promise<ResourceModel> {
    try {
      const res = await Net.getInstance().post<ResourceModel>({
        path: "/resources",
        body: {
          type: ResourceType.TEXT,
          name: data.name.length > 0? data.name: undefined,
          title: data.title,
          description: data.description,
          date: data.date.getTime(),
          managerCanManage: data.managerCanManage,
          lang: data.lang
        }
      })
      return res.success
    } catch (error) {
      throw error
    }
  }

  async createImage(data: IResourcesPostNetCreateImageRequest): Promise<ResourceModel> {
    try {
      const formData = new FormData()
      formData.append("type", ResourceType.IMAGE)
      if (data.name.length > 0) formData.append("name", data.name)
      formData.append("title", data.title)
      formData.append("description", data.description)
      formData.append("date", data.date.getTime().toString())
      formData.append("managerCanManage", String(data.managerCanManage))
      if (data.uuidParentResource) formData.append("uuidParentResource", data.uuidParentResource)
      formData.append("file", data.file) // add file after all the data
      const res = await Net.getInstance().post<ResourceModel>({
        path: "/resources/file",
        body: formData,
        multipart: true
      })
      return res.success
    } catch (error) {
      throw error
    }
  }

  async createAlbum(data: IResourcesPostNetCreateAlbumRequest): Promise<ResourceModel> {
    try {
      const res = await Net.getInstance().post<ResourceModel>({
        path: "/resources",
        body: {
          type: ResourceType.ALBUM,
          name: data.name.length > 0? data.name: undefined,
          title: data.title,
          description: data.description,
          date: data.date.getTime(),
          managerCanManage: data.managerCanManage,
          uuidParentResource: data.uuidParentResource,
        }
      })
      return res.success
    } catch (error) {
      throw error
    }
  }

  async createGallery(data: IResourcesPostNetCreateGalleryRequest): Promise<ResourceModel> {
    try {
      const res = await Net.getInstance().post<ResourceModel>({
        path: "/resources",
        body: {
          type: ResourceType.GALLERY,
          name: data.name.length > 0? data.name: undefined,
          title: data.title,
          description: data.description,
          date: data.date.getTime(),
          managerCanManage: data.managerCanManage,
        }
      })
      return res.success
    } catch (error) {
      throw error
    }
  }
}