import Net from "../../net.http";

interface ILoginAuthPostHttpLoginData {
  username: string
  password: string
}

interface ILoginAuthPostHttpLoginResponse {
  access: string
  refresh: string
  user: {
    uuid: string
    email: string
    name: string
    role: string // FIXME: Proper role type
    username: string
  }
}

export default class LoginAuthPostHttp {
  async login(data: ILoginAuthPostHttpLoginData) {
    try {
      const res = await Net.getInstance().post<ILoginAuthPostHttpLoginResponse>({
        path: "/auth/login",
        body: {
          username: data.username,
          password: data.password
        }
      })
      return res.success
    } catch (error) {
      throw error;
    }
  }
}