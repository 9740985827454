import { FunctionComponent } from "react"

const LoadingElement: FunctionComponent = () => {
  return <div className="
    flex
    justify-center
    items-center
  ">
    <div
      className="
        animate-spin
        rounded-full
        border-4
        border-t-4
        border-neutral-300
        border-t-sky-400
        h-6
        w-6
      "
    ></div>
  </div>
}

export default LoadingElement