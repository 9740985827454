import { FunctionComponent } from "react";

interface IDefaultHeaderWidgetProps {
  title: string
}

const DefaultHeaderWidget: FunctionComponent<IDefaultHeaderWidgetProps> = ({ title, children }) => {
  return <div className="h-16 border-b border-b-neutral-200">
    <div className="flex flex-1 flex-row items-center container max-w-screen-lg h-full px-2">
      <p className="text-2xl font-medium">{title}</p>
      <div className="flex flex-1 flex-row justify-end">
        {children}
      </div>
    </div>
  </div>
}

export default DefaultHeaderWidget