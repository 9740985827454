import { configureStore } from '@reduxjs/toolkit'
import UserSlice, { USER_SLICE_NAME } from './slices/user.slices.redux'

export const store = configureStore({
  reducer: {
    [USER_SLICE_NAME]: UserSlice
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch