import { IModelsCommon } from "../commons/models.common"

export enum ResourceTextLanguages {
  ENGLISH = "en",
  HINDI = "hi"
}

export enum ResourceType {
  // A gallery holds albums | has no parent
  GALLERY = "GALLERY",
  // An album holds images or videos | can have gallery as parent or can be standalone
  ALBUM = "ALBUM",
  // Image can be standalone or have album as parent
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  PDF = "PDF",
  TEXT = "TEXT" // text can be in multiple languages
}

type BaseTypeFiles = {
  url: string
  mimetype: string
  size: number
}

type BaseType = IModelsCommon & {
  name: string // name of the resource, alphanumeric
  title: string
  description: string
  date: Date // If a date needs to be shown for the data this date field it to be used
  managerCanManage: boolean // Resources that can be edited by the manager
  standalone: boolean // standalone are the resources that are to be used by themselves and are not part of any other resource
  uuidParentResource?: string // uuid of the resource which this resource is a part of
}

type ResourceGallery = BaseType & {
  type: ResourceType.GALLERY
}

type ResourceAlbum = BaseType & {
  type: ResourceType.ALBUM,
  uuidImageThumbnail?: string // uuid of thumbnail image
}

type ResourceImage = BaseType & BaseTypeFiles & {
  type: ResourceType.IMAGE,
  url: string // url of image
  urlBackup?: string // backup url of image
}

type ResourceVideo = BaseType & BaseTypeFiles & {
  type: ResourceType.VIDEO,
}

type ResourcesText = BaseType & {
  type: ResourceType.TEXT,
  lang: {[key in keyof typeof ResourceTextLanguages]?: any}
}

type ResourceModel = ResourceGallery | ResourceAlbum | ResourceImage | ResourceVideo | ResourcesText

export type {
  ResourceModel,
  ResourceGallery,
  ResourceAlbum,
  ResourceImage,
  ResourceVideo,
  ResourcesText
}