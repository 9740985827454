import { FunctionComponent } from "react"
import { useParams } from "react-router-dom"
import { RESOURCE_STRING_TO_TYPE } from "../../../constants/resource-names.constants"
import ListResourcesWidget from "../../../widgets/resources/list.resources.widgets"

const IndexResourcesDashboardPages: FunctionComponent = () => {
  const { resourceType } = useParams()

  return resourceType? (
    <ListResourcesWidget
      key={resourceType}
      resourceType={RESOURCE_STRING_TO_TYPE[resourceType]}
    />
  ): <></>
}

export default IndexResourcesDashboardPages