import { FunctionComponent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ContainerDashboardComponent from "../../components/container.components";
import { RESOURCE_TYPE_TO_NAMES } from "../../constants/resource-names.constants";
import LoadingElement from "../../elements/loading.elements";
import ResourcesGetHttp from "../../http/resources/get.resources.http";
import { ResourceType, ResourceModel } from "../../models/resource.models";
import HeaderResourcesWidget from "./header.resources.widgets";
import { ReactComponent as ExpandSvg } from "../../constants/svg/expand.svg";

interface IShowResourcesWidgetProps {
  resourceType: ResourceType
}

const ListResourcesWidget: FunctionComponent<IShowResourcesWidgetProps> = ({ resourceType }) => {
  const { pathname } = useLocation()

  const header = <HeaderResourcesWidget  title={`${RESOURCE_TYPE_TO_NAMES[resourceType]} Resources`}>
    <Link to={`${pathname}/new`}>
      <p className="px-4 py-2 bg-sky-200 rounded-lg font-semibold uppercase">ADD {RESOURCE_TYPE_TO_NAMES[resourceType]}</p>
    </Link>
  </HeaderResourcesWidget>

  const [ loading, setLoading ] = useState(true)
  const [ hasMore, setHasMore ] = useState(false)
  const [ resourcesList, setResourcesList ] = useState<Array<ResourceModel>>([])

  async function fetchResources() {
    setLoading(true)
    const response = await new ResourcesGetHttp().get({
      type: resourceType,
    })
    setResourcesList([...resourcesList, ...response.resources])
    setLoading(false)
  }

  useEffect(() => {
    fetchResources()
  }, [])

  return <ContainerDashboardComponent header={header}>
    <div className="container max-w-screen-lg px-2 py-6 grid grid-cols-2 gap-4">
      {resourcesList.map(resource => {
        let additionalViews
        if (resource.type === ResourceType.TEXT) {
          additionalViews = <>
            <p className="px-4 uppercase text-sm text-neutral-400 mt-4">Text - English</p>
            <p className="px-4"> {resource.lang.ENGLISH}</p>
          </>
        }
        if (resource.type === ResourceType.IMAGE) {
          additionalViews = <>
            <div className="mx-4 mt-4 rounded-lg overflow-hidden aspect-video">
              <img src={resource.url} className="w-full h-full object-cover" />
            </div>
          </>
        }
        if (resource.type === ResourceType.ALBUM) {
          additionalViews = <>
          </>
        }
        if (resource.type === ResourceType.GALLERY) {
          additionalViews = <>
          </>
        }
        return <div key={resource.uuid} className="border border-neutral-200 rounded-lg pb-4">
          <div className="flex flex-row justify-between items-center border-b border-neutral-200">
            <p className="px-4 font-semibold">{RESOURCE_TYPE_TO_NAMES[resource.type]}</p>
            <div className="px-4 py-1">
              <Link to={`${pathname}/${resource.uuid}`}>
                <ExpandSvg className="border border-neutral-200 rounded-lg hover:bg-sky-200 w-10 h-10 p-2 fill-neutral-400 hover:fill-neutral-800" />
              </Link>
            </div>
          </div>
          <p className="px-4 uppercase text-sm text-neutral-400 mt-4">Name</p>
          <p className="px-4"> {resource.name}</p>
          <p className="px-4 uppercase text-sm text-neutral-400 mt-4">Title</p>
          <p className="px-4"> {resource.title}</p>
          <p className="px-4 uppercase text-sm text-neutral-400 mt-4">Description</p>
          <p className="px-4"> {resource.description}</p>
          
          {additionalViews}
        </div>
      })}
      {(!loading && hasMore) && <div className="border border-neutral-200 p-4 rounded-lg col-span-2">
        <p>Load More</p>
      </div>}
      {(!loading && resourcesList.length === 0) && <div className="p-4 rounded-lg col-span-2">
        <p className="text-center">No Resources Found</p>
      </div>}
      {loading && <div className="border border-neutral-200 p-4 rounded-lg col-span-2">
        <LoadingElement />
      </div>}
    </div>
  </ContainerDashboardComponent>
}

export default ListResourcesWidget