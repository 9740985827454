import { FunctionComponent } from "react";
import DefaultHeaderWidget from "../default-header.widgets";

interface IHeaderResourcesWidgetProps {
  title: string
}

const HeaderResourcesWidget: FunctionComponent<IHeaderResourcesWidgetProps> = ({ title, children }) => {
  return <DefaultHeaderWidget title={title}>
    {children}
  </DefaultHeaderWidget>
}

export default HeaderResourcesWidget